import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'

import styles from './index.module.scss'


const LawPage = () => {

    return (
        <div>
            <SEO title="特定商取引法に基づく表示" />
            <Header hasBack={true} />

            <div className={styles.box}>
                <h1 className={styles.title_en}>Specified Commercial Transaction Act</h1>
                <div className={styles.title_ja}>特定商取引法に基づく表示</div>

                <section>
                    <h2>事業者名</h2>
                    <div>
                        <p>快適ライフサービス株式会社</p>
                    </div>
                </section>

                <section>
                    <h2>店舗運営責任者</h2>
                    <div>
                        <p>代表取締役 小林 將一</p>
                    </div>
                </section>

                <section>
                    <h2>住所</h2>
                    <div>
                        <p>〒152-0031　東京都目黒区中根2-12-10</p>
                    </div>
                </section>

                <section>
                    <h2>連絡先</h2>
                    <div>
                        <p>Tel：03-6421-4120<br />Mail：info@thefit.jp</p>
                    </div>
                </section>

                <section>
                    <h2>お申込み方法</h2>
                    <div>
                        <p>ウェブ上でのお申込み</p>
                    </div>
                </section>

                <section>
                    <h2>販売価格（入会時初期費用）</h2>
                    <div>
                        <p>店舗に定める月会費2ヶ月分（申込期間によって1ヶ月分の場合がございます）</p>
                        <ul>
                            <li>入会金</li>
                            <li>登録事務手数料</li>
                            <li>法令に定める税</li>
                        </ul>
                        <div className={styles.attention}>
                            <p>※初回分以降の月会費は、ご登録いただいたクレジットカードより自動支払いでございます。</p>
                        </div>
                    </div>
                </section>

                <section>
                    <h2>お支払い方法</h2>
                    <div>
                        <p>クレジットカード決済（VISA、Mastercard、JCB、AMEX、DINERS）</p>
                    </div>
                </section>

                <section>
                    <h2>商品のお引き渡し時期</h2>
                    <div>
                        <p>当社所定の手続き終了後（利用者承認後）、すぐにご利用頂けます。</p>
                    </div>
                </section>

                <section>
                    <h2>商品代金のお支払い時期</h2>
                    <div>
                        <p>お申込み確定時に決済。利用カード会社引落の規定に準ずる。</p>
                    </div>
                </section>

                <section>
                    <h2>返品（返金）に関する事項</h2>
                    <div>
                        <p>商品の性質上、返品はお受けできません。<br />WEBで決済及びお支払頂いた入会諸費用は理由の如何に関わらずご返金できません。<br />また、退会、除名に伴う返還（返金）に関しては、当社所定の会員規定、及び、当社が指定する集金代行会社の規定に基づきます。</p>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default LawPage
